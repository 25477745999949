/*
	CSS voor de instrumentenbouw site
*/

@import "w3.css";

* {
    box-sizing: border-box;
}

img {
    image-orientation: from-image;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

html {
	font-family: "Verdana", sans-serif;
}

[class*="col-"] {
    float: left;
    padding: 15px;
    width: 100%;
}

@media only screen and (max-width: 599px) {
	.hide-small {
        display: none !important
	}
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .col-s-1 { width: 8.33%;}
    .col-s-2 { width: 16.66%;}
    .col-s-3 { width: 25%;}
    .col-s-4 { width: 33.33%;}
    .col-s-5 { width: 41.66%;}
    .col-s-6 { width: 50%;}
    .col-s-7 { width: 58.33%;}
    .col-s-8 { width: 66.66%;}
    .col-s-9 { width: 75%;}
    .col-s-10 { width: 83.33%;}
    .col-s-11 { width: 91.66%;}
    .col-s-12 { width: 100%;}

	header .logo {
		height: 5vw;
		margin-top: 0.5em;
		margin-right: 0.5em;
	}
	
	.hide-medium {
		display: none;
	}
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .col-1 { width: 8.33%;}
    .col-2 { width: 16.66%;}
    .col-3 { width: 25%;}
    .col-4 { width: 33.33%;}
    .col-5 { width: 41.66%;}
    .col-6 { width: 50%;}
    .col-7 { width: 58.33%;}
    .col-8 { width: 66.66%;}
    .col-9 { width: 75%;}
    .col-10 { width: 83.33%;}
    .col-11 { width: 91.66%;}
    .col-12 { width: 100%;}

	header .logo {
		height: 6vw;
		margin-top: 0.5em;
		margin-right: 0.5em;
	}

	.hide-large {
		display: none;
	}
}

.menu {
	max-width: 240px;
}

.menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menu li {
    padding: 0.2em;
    margin-bottom: 0.3em;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.menu li.sub-li {
	padding-left: 1.5em;
	background-color: #f8f8f8;
}

@media only screen and (min-width: 600px) {
	.menu li {
	    padding: 0.3em;
	    margin-bottom: 0.75em;
	    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	}
}

article {
	max-width: 960px;
}

.menu ul a {
	text-decoration: none;
}

.menu li:hover {
    text-decoration: underline;
}

.show-menu {
	display: block !important;
}

.hide-menu {
	display: none;
}

.balkjes {
	background: url("../images/drie-balkjes.svg") no-repeat top right;
	float: right;
	padding: 1em;
	vertical-align: middle;
}

a {
	color: #555;
}

a:hover {
	color: #222f44;
}

a img {
	border: none;
}

h1, h2, h3, h4 {
	color: #555;
}

h3 {
	padding-top: 15px;
}

header {
	border-bottom: 1px solid #888;
	padding-left: 1em;
}

header h1 {
	font-size: 3.5vw;
	color: #666;
	float: left;
	text-align: center;
}

header .logo {
	float: left;
	height: 2em;
	margin-right: 0.25em;
	margin-bottom: 0.25em;
	vertical-align: middle;
}

header a {
	text-decoration: none;
}

footer {
	clear: both;
	border-top: 1px solid #888;
	padding-top: 0.4em;
	margin-top: 2em;
	font-size: x-small;
	color: #555;
}

@media only screen and (min-width: 600px) {
	header .logo {
		height: 5vw;
		margin-top: 0.5em;
		margin-right: 0.5em;
	}

	.menu li {
	    padding: 0.5em;
	    margin-bottom: 1em;
	    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	}
}

@media only screen and (min-width: 768px) {
	header .logo {
		height: 6vw;
		margin-top: 0.5em;
		margin-right: 0.5em;
	}
}

article img {
	max-width: 100%;
}


ul.loc {
	list-style: none;
	padding: 0;
	margin: 0.2em;
	display: block;
}

ul.loc li {
	color: #333;
	display: inline;
	font-size: x-small;
}

ul.loc li + li:before {
	content: "  »  ";
}

table.contact, table.prijslijst {
	border-collapse: collapse;
}

table.prijslijst {
	margin-left: 2em;
}

table.contact caption {
	text-align: left;
	padding: 1em;	
}

table.contact td {
	padding: 0.1em 1em 0.1em 0.1em;
}

ul.accessoire-lijst {
	list-style: none;
}

.prijslijst thead tr {
	border-bottom: solid 1px #ccc;
}

.prijslijst th {
	font-weight: normal;
	font-style: italic;
	text-align: left;
}

.prijslijst td.prijs, .prijslijst th.prijs {
	text-align: right;
}

/*
	tekst ballon, voor prijslijst bijvoorbeeld
 */

.ballon {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.ballon .ballontext {
	visibility: hidden;
	width: 200px;
	background-color: #555;
	color: #fff;
	text-align: center;
	padding: 5px 4px;
	border-radius: 6px;

	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;

	opacity: 0;
	transition: opacity 0.3s;
}

.ballon .ballontext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border: 5px solid transparent;
	border-top-color: #555;
}

.ballon:hover .ballontext {
	visibility: visible;
	opacity: 1;
}

/*
	mijn eigen image gallery
*/

.carousel {
	width: 280px;
	margin: auto;
}

.ibs-post-abstract .carousel {
	display: none;
}

.carousel .slide-container {
	position: relative;
	padding-bottom: 1em;
	height: 210px;

	white-space: nowrap;
	text-align: center;
}

.carousel .slides {
	/*background-color: #444;*/
	position: inherit;
	margin-bottom: 0.5em;
	height: 100%;
}

.carousel .helper {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.carousel .slides img {
	margin-left: auto;
	margin-right: auto;
	max-height: 225px;
	vertical-align: middle;
}

@media only screen and (min-width: 400px) {
	.carousel {
		width: 380px;
	}
	.carousel .slide-container {
		height: 285px;
	}
	.carousel .slides img {
		max-height: 285px;
	}
}

@media only screen and (min-width: 1024px) {
	.carousel {
		width: 720px;
	}
	.carousel .slide-container {
		height: 540px;
	}
	.carousel .slides img {
		max-height: 540px;
	}
}



.carousel .desc {
	padding-bottom: 0.75em;
}

.carousel .cursor {
	cursor: pointer;
}

.carousel .ribbon img {
	padding: 0;
	margin: 0;
	width: 128px;
}

.carousel .ribbon img.scrollbtn {
	margin-top: 3px;
	width: 25px;
}

.carousel .ribbon img.scrollbtn:hover {
	background-color: #ffbf80;
}

.carousel .ribbon img.links {
	float: left;
}

.carousel .ribbon img.rechts {
	float: right;
}

.carousel .ribbon .strip {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	overflow-x: auto;
	margin-bottom: 6px;
}

.carousel .strip img {
	opacity: 0.6;
	min-height: 50%;
	margin-top: auto;
	margin-bottom: auto;
}

.carousel .strip img:hover, .carousel .strip img.selected {
	opacity: 1;
}

.carousel .slide-container .next, .carousel .slide-container .prev {
	opacity: 0;

	transition: 0.4s ease;
}

.carousel .slide-container:hover .next, .carousel .slide-container:hover .prev {
	opacity: 0.7;
}

.carousel .next, .carousel .prev {
	cursor: pointer;
	position: absolute;
	top: 50%;
	width: auto;
	padding: 16px;
	margin-top: -22px;
	background-color: #888;
	color: white;
	font-weight: bold;
	text-decoration: none;
	font-size: 18px;
	border-radius: 0 3px 0 3px;
	user-select: none;
	-webkit-user-select: none;
	
	transition: 0.4s ease;
}

.carousel .prev {
	left: 0;
}

.carousel .next {
	right: 0;
	border-radius: 3px 0 0 3px;
}

.carousel .next:hover, .carousel .prev:hover {
	background-color: rgba(0, 0, 0, 0.8);
}

.carousel .numbertext {
	color: #f2f2f2;
	font-size: 12px;
	padding: 8px 12px;
	position: absolute;
	top: 0;
}

/* Caption text */
.carousel .text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 32px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.carousel .numbertext {
  color: #f2f2f2;
  font-size: medium;
  font-weight: bold;
  padding: 0.5em 1em;
  position: absolute;
  top: 0;
}

.carousel .caption-container {
	text-align: center;
	background-color: #222;
	padding: 2px 16px;
	color: white;
}

.carousel .row:after {
	content: "";
	display: table;
	clear: both;
}

.carousel .column {
	float: left;
	width: 16.66%;
}

.carousel .demo {
	opacity: 0.6;
}

.carousel .active, .carousel .demo:hover {
	opacity: 1;
}


/*
	modal image viewer
*/

/* Style the Image Used to Trigger the Modal */
img.zoomable {
	width: 100%;
	max-width: 300px;

	cursor: pointer;
	transition: 0.2s;
}

img.zoomable:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 75px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

.modal .next, .modal .prev {
	cursor: pointer;
	position: absolute;
	top: 50%;
	width: auto;
	padding: 16px 8px;
	margin-top: -22px;
	background-color: rgba(0, 0, 0, 0.2);
	color: white;
	font-weight: bold;
	text-decoration: none;
	font-size: 18px;
	border-radius: 0 3px 0 3px;
	user-select: none;
	-webkit-user-select: none;

	transition: 0.4s ease;
}

.modal .prev {
	left: 0;
}

.modal .next {
	right: 0;
	border-radius: 3px 0 0 3px;
}

.modal .next:hover, .modal .prev:hover {
	background-color: rgba(170, 170, 170, 0.8);
}


/* Modal Content (Image) */
.modal-content {
	margin: auto;
	display: block;
	width: auto;
	height: auto;
	max-width: calc(100% - 5px);
	max-height: calc(100% - 5px);
}

@media only screen and (min-width: 512px) {
	.modal-content {
		max-width: calc(100% - 25px);
		max-height: calc(100% - 25px);
	}
}

@media only screen and (min-width: 1024px) {
	.modal-content {
		max-width: calc(100% - 50px);
		max-height: calc(100% - 50px);
	}
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
.modal-caption {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
	text-align: center;
	color: #ccc;
	padding: 10px 0;
	height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, .modal-caption {
	animation-name: zoom;
	animation-duration: 0.3s;
}

@keyframes zoom {
	from {transform:scale(0)}
	to {transform:scale(1)}
}

/* The Close Button */
.modal-close {
	position: absolute;
	top: 15px;
	right: 35px;
	color: #f1f1f1;
	font-size: 40px;
	font-weight: bold;
	transition: 0.3s;
}

.modal-close:hover,
.modal-close:focus {
	color: #bbb;
	text-decoration: none;
	cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
	.modal-content {
		width: 100%;
		height: unset;
		max-width: unset;
		max-height: unset;
	}
}




/* Fading animation */
.fade {
			animation-name: fade;
			animation-duration: 1s;
	-webkit-animation-name: fade;
	-webkit-animation-duration: 1s;
}

@-webkit-keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}

/*
	pager navigator
*/

.navigator {
	padding-bottom: 0.5em;
	border-bottom: 1px solid black;
}

.navigator .left {
	float: left;
	clear: none;
}

.navigator .right {
	float: right;
	clear: none;
}

.navigator .bottom {
	clear: both;
}

.navigator a {
	color: #aaa;
	text-decoration: none;
}

.navigator a.enabled {
	color: black;
}

.navigator a:hover {
	color: #555;
	text-decoration: underline;
}

/*
 voor de svg van het toetsenbord
 */

.kbl {
	stroke: #222222;
	stroke-width: 1px;
	fill: none;
}

rect.key-rect {
	stroke: #222222;
	stroke-width: 1px;
	fill: none;

}

rect.boven {
	fill: #666666;
}

text {
	font-size: x-small;
}

text.boven {
	fill: white;
}

td.fretting {
	width: 2em;
}

td.fretting:hover {
	background-color: #bbbbbb;
}

tr.active td {
	background-color: rgba(213, 245, 181, 0.2);
}

#snaar label, #spanning-calculator label, form[name='stemming'] label {
	display: inline-block;
	width: 20em;
}

.dialog label {
	display: inline-block;
	width: 15em;
}

.config-overzicht label {
	display: inline-block;
	width: 20em;
}

td.kolom-spanning, th.kolom-spanning {
	text-align: right;
}

td.wijzig-snaar, td.verwijder-snaar {
	width: 16px;
}

td.wijzig-snaar:hover, td.verwijder-snaar:hover {
	cursor: pointer;
	background-color: #bbbbbb;
}

/*td.kolom-diameter, th.kolom-diameter {*/
	/*text-align: right;*/
/*}*/


/*#spanning-calculator-form label {*/
	  /*width: 20em;*/
  /*}*/

/* spanning grafiek */

.plot {
	fill: white;
}

g.dot circle {
	fill: orange;
	cursor: pointer;
}

.curve {
	fill: none;
	stroke: #ff5500;
	stroke-width: 1px;
}

div.tooltip {
	position: absolute;
	white-space: pre;
	width: 7em;
	height: auto;
	padding: 0.3em;
	font: small sans-serif;
	color: white;
	background: #666666;
	border: 1px solid black;
	border-radius: 4px;
	pointer-events: none;
}

.plot-container > svg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

input[type='number'] {
	-moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='file'].btn-input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.w3-container {
	padding-bottom: 8px;
}

.blok-btn * {
	clear: none;
}

.bogus {
	color: #3f67a5;
}
